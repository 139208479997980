import {all, takeEvery, put, fork} from 'redux-saga/effects';

import * as actionTypes from '../actions/actionTypes';
import * as actions from '../actions/index';
import * as API from '../api';

export function* loadSearchProcessDetail() {
  yield takeEvery(actionTypes.LOAD_SEARCH_PROCESSDETAIL, function* (action) {
    yield put(actions.loading(true));

    try {
      const response = yield API.loadSearchProcessDetail(action.id);

      if (response.data) {
        yield put(actions.loadSearchProcessDetailSuccess(response.data));
      } else {
        yield put(actions.loadSearchProcessDetailFail());
      }
      yield put(actions.loading(false));
    } catch (error) {
      yield put(actions.loadSearchProcessDetailFail());
      yield put(actions.loading(false));
    }
  });
}

export default function* searchProcessDetailSaga() {
  yield all([fork(loadSearchProcessDetail)]);
}
