import React from 'react';

const ResolutionNotSupport = () => {
  return (
    <div class="mobile-warning">
      <div class="top-bar">
        <img
          src={require('./../../images/idea-logo.png')}
          alt=""
          class="logo"
        />

      </div>

      <div class="container">
        <div class="row">
          <div class="col-xs-12 col-sm-12">
            <div class="text-center">
              <h1>Database</h1>

              <h3 class="hlp-mb-10">
                Constitution-Building Processes in Conflict-Affected States
              </h3>
              <p class="hlp-mb-20 fz14 bold tc-dark">
                Our tool is not available for your resolution
              </p>

              <img src={require('./../../images/devices.png')} alt="" />

              <p class="fz14 bold tc-dark">
                To use our tool optimally, please switch to desktop or tablet
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResolutionNotSupport;
