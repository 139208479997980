import * as actionTypes from './actionTypes';

export const loadProperties = () => {
  return {
    type: actionTypes.LOAD_PROPERTIES,
  };
};

export const loadPropertiesSuccess = (data) => {
  return {
    type: actionTypes.LOAD_PROPERTIES_SUCCESS,
    payload: data,
  };
};

export const loadPropertiesFail = () => {
  return {
    type: actionTypes.LOAD_PROPERTIES_FAIL,
  };
};

export const deleteProperty = (id) => {
  return {
    type: actionTypes.DELETE_PROPERTY,
    id,
  };
};

export const deletePropertySuccess = (data) => {
  return {
    type: actionTypes.DELETE_PROPERTY_SUCCESS,
    payload: data,
  };
};

export const deletePropertyFail = () => {
  return {
    type: actionTypes.DELETE_PROPERTY_FAIL,
  };
};

export const createPropertyOverlay = (value) => {
  return {
    type: actionTypes.PROPERTY_CREATE_OVERLAY,
    payload: value,
  };
};

export const updateCreatePropertyForm = ({prop, value}) => {
  return {
    type: actionTypes.UPDATE_CREATEPROPERTY_FORM,
    payload: {prop, value},
  };
};

export const addNewProperty = (data) => {
  return {
    type: actionTypes.ADD_PROPERTY,
    data,
  };
};

export const addNewPropertySuccess = () => {
  return {
    type: actionTypes.ADD_PROPERTY_SUCCESSFUL,
  };
};

export const addNewPropertyFail = (data) => {
  return {
    type: actionTypes.ADD_PROPERTY_FAIL,
    payload: data,
  };
};

export const editProperties = (id) => {
  return {
    type: actionTypes.PROPERTY_EDIT_FORM,
    id,
  };
};

export const editPropertiesSuccess = (data) => {
  return {
    type: actionTypes.PROPERTY_EDIT_FORM_SUCCESS,
    payload: data,
  };
};

export const editPropertiesFail = () => {
  return {
    type: actionTypes.PROPERTY_EDIT_FORM_FAIL,
  };
};

export const sortProperties = (data) => {
  return {type: actionTypes.PROPERTY_SORT, data, payload: true};
};

export const sortPropertiesSuccess = (data) => {
  return {type: actionTypes.PROPERTY_SORT, data, payload: false};
};

export const searchProperty = (data) => {
  return {
    data,
    type: actionTypes.SEARCH_DATA_PROPERTIES,
  };
};

export const searchPropertySuccess = (data) => {
  return {
    type: actionTypes.SEARCH_DATA_PROPERTIES_SUCCESS,
    payload: data,
  };
};

export const searchPropertyFail = () => {
  return {
    type: actionTypes.SEARCH_DATA_PROPERTIES_FAIL,
  };
};

export const resetSearch = () => {
  return {
    type: actionTypes.RESET_SEARCH,
  };
};

export const activeProperty = (data) => {
  return {
    type: actionTypes.ACTIVE_PROPERTY,
    data,
  };
};

export const activePropertySuccess = (data) => {
  return {
    type: actionTypes.ACTIVE_PROPERTY_SUCCESS,
    data,
  };
};

export const activePropertyFail = () => {
  return {
    type: actionTypes.ACTIVE_PROPERTY_FAIL,
  };
};
