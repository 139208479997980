import * as actionTypes from '../actions/actionTypes';
import produce from 'immer';

const INITIAL_STATE = {
  pinboardOverlayActive: false,
  pinStagesOverlayActive: false,
  pinboard: {
    process: [],
    stage: [],
    property: [],
    lastUpdate: '',
  },
};

export default (state = INITIAL_STATE, action) => {
  let id = '';
  let name = '';
  let process_id = '';
  let process_name = '';
  let stage_id = '';
  let stage_name = '';
  let found = false;
  let ts = '';

  switch (action.type) {
    case actionTypes.PIN_PROCESS:
      return produce(state, (draft) => {
        id = action.payload.id;
        name = action.payload.name;

        ts = Math.floor(Date.now() / 1000);

        let found = draft.pinboard.process.find((process) => process.id === id);

        if (found) {
          const process = state.pinboard.process.filter(
            (process) => process.id !== id,
          );
          draft.pinboard.process = process;
          draft.pinboard.lastUpdate = ts;
        } else {
          draft.pinboard.process.push({id, name});
          draft.pinboard.lastUpdate = ts;
        }
      });

    case actionTypes.PIN_STAGE:
      return produce(state, (draft) => {
        id = parseInt(action.payload.id);
        name = action.payload.name;
        process_id = action.payload.process_id.toString();
        process_name = action.payload.process_name;



        ts = Math.floor(Date.now() / 1000);

        found = state.pinboard.stage.find(
          (stage) => stage.id === id && stage.process_id === process_id,
        );

        if (found) {
          const stage = state.pinboard.stage.filter(
            (stage) => !(stage.id === id && stage.process_id === process_id),
          );

          draft.pinboard.stage = stage;
          draft.pinboard.lastUpdate = ts;
        } else {
          draft.pinboard.stage.push({id, name, process_id, process_name});
          draft.pinboard.lastUpdate = ts;
        }
      });

    case actionTypes.PIN_PROPERTY:
      return produce(state, (draft) => {
        id = action.payload.id;
        name = action.payload.name;
        process_id = action.payload.process_id;
        process_name = action.payload.process_name;
        stage_id = action.payload.stage_id;
        stage_name = action.payload.stage_name;

        ts = Math.floor(Date.now() / 1000);

        found = state.pinboard.property.find(
          (property) =>
            property.id === id &&
            property.process_id === process_id &&
            property.stage_id === stage_id,
        );

        if (found) {
          const property = state.pinboard.property.filter(
            (property) =>
              !(
                property.id === id &&
                property.process_id === process_id &&
                property.stage_id === stage_id
              ),
          );

          draft.pinboard.property = property;
          draft.pinboard.lastUpdate = ts;
        } else {
          draft.pinboard.property.push({
            id,
            name,
            process_id,
            process_name,
            stage_id,
            stage_name,
          });
          draft.pinboard.lastUpdate = ts;
        }
      });

    case actionTypes.PINBOARD_OVERLAY:
      return produce(state, (draft) => {
        draft.pinboardOverlayActive = action.payload;
      });

    case actionTypes.CLEAR_PINBOARD:
      return produce(state, (draft) => {
        ts = Math.floor(Date.now() / 1000);
        draft.pinboard = INITIAL_STATE.pinboard;
        // draft.pinboard.lastUpdate = ts;
      });

    case actionTypes.PIN_STAGES_OVERLAY:
      return produce(state, (draft) => {
        draft.pinStagesOverlayActive = action.payload;
      });

    default:
      return state;
  }
};
