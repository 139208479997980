import * as actionTypes from './actionTypes';

export const loadProcesses = (offset = 0, sort = 'asc') => {
  return {
    type: actionTypes.LOAD_PROCESSES,
    data: { offset, sort },
  };
};

export const loadProcessesSuccess = (data, offset) => {
  return {
    type: actionTypes.LOAD_PROCESSES_SUCCESS,
    payload: {
      data,
      offset,
    },
  };
};

export const loadProcessesFail = () => {
  return {
    type: actionTypes.LOAD_PROCESSES_FAIL,
  };
};

export const createOverlay = (value) => {
  return {
    type: actionTypes.PROCESSES_CREATE_OVERLAY,
    payload: value,
  };
};

export const updateCreateProccessesForm = ({ prop, value }) => {
  return {
    type: actionTypes.UPDATE_CREATEPROCESSES_FORM,
    payload: { prop, value },
  };
};

export const addNewProccess = (data) => {
  return {
    type: actionTypes.CREATE_PROCESSES_LOADING,
    data,
  };
};

export const addNewProccessSuccess = (data) => {
  return {
    type: actionTypes.ADD_PROCESSES_SUCCESSFUL,
    payload: data,
  };
};

export const addNewProccessFail = (data) => {
  return {
    type: actionTypes.ADD_PROCESSES_FAIL,
    payload: data,
  };
};

export const setProcessFileErrors = (errors) => {
  return {
    type: actionTypes.SET_PROCESS_FILE_ERRORS,
    payload: errors,
  };
};

export const deleteProcessOverlay = (value) => {
  return {
    type: actionTypes.DELETE_PROCESS_OVERLAY,
    payload: value,
  };
};

export const setProcessToDelete = (id, name) => {
  return {
    type: actionTypes.PROCESS_TO_DELETE,
    payload: { id, name },
  };
};

export const deleteProcess = (data) => {
  return {
    type: actionTypes.DELETE_PROCESS,
    data,
  };
};

export const deleteProcessSuccess = () => {
  return {
    type: actionTypes.DELETE_PROCESS_SUCCESSFUL,
  };
};

export const deleteProcessFail = () => {
  return {
    type: actionTypes.DELETE_PROCESS_FAIL,
  };
};

export const searchProcess = (value) => {
  return {
    type: actionTypes.SEARCH_DATA,
    value,
  };
};

export const searchProcessSuccess = (data) => {
  return {
    type: actionTypes.SEARCH_DATA_SUCCESS,
    payload: data,
  };
};

export const searchProcessFail = () => {
  return {
    type: actionTypes.SEARCH_DATA_FAIL,
  };
};

export const resetSearch = () => {
  return {
    type: actionTypes.RESET_SEARCH,
  };
};

export const sortByLastUpdated = (order) => {
  return {
    type: actionTypes.SORT_BY_LAST_UPDATED,
    payload: {
      order,
    },
  };
};
