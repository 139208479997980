import * as actionTypes from '../actions/actionTypes';
import produce from 'immer';

const INITIAL_STATE = {
  data: {
    data: [],
    total: 0,
  },
  offset: 0,
  createOverlayActive: false,
  formData: {
    // record_id: null,
    process_name: '',
    alternate_names: '',
    no_of_bodies: '1',
    excel: '',
  },
  formError: {},
  createProcessLoading: false,

  deleteProcessOverlayActive: false,
  processToDeleteId: '',
  processToDeleteName: '',
  addProcessSuccess: false,
  addProcessFileErrors: null,
  newProcessID: null,
  deleteProcessSuccess: false,
  testFail: false,
  searchFlow: false,
  searchData: {
    data: [],
    total: 0,
  },
  sortByLastUpdatedOrder: 'asc',
};

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case actionTypes.LOAD_PROCESSES_SUCCESS:
      return produce(state, (draft) => {
        draft.data.data = action.payload.data;
        draft.offset = action.payload.offset;
        draft.addProcessSuccess = false;
        draft.deleteProcessSuccess = false;
      });
    case actionTypes.PROCESSES_CREATE_OVERLAY:
      return produce(state, (draft) => {
        draft.createOverlayActive = action.payload;
        draft.formData = INITIAL_STATE.formData;
      });

    case actionTypes.CREATE_PROCESSES_LOADING:
      return produce(state, (draft) => {
        draft.createProcessLoading = true;
      });
    case actionTypes.SET_PROCESS_FILE_ERRORS:
      return produce(state, (draft) => {
        draft.addProcessFileErrors = action.payload;
      });

    case actionTypes.UPDATE_CREATEPROCESSES_FORM:
      return produce(state, (draft) => {
        draft.formData[action.payload.prop] = action.payload.value;
      });
    case actionTypes.ADD_PROCESSES_SUCCESSFUL:
      return produce(state, (draft) => {
        draft.formError = {};
        draft.createProcessLoading = false;
        draft.addProcessSuccess = true;

        if (action.payload?.errors && action.payload?.id) {
          draft.addProcessFileErrors = action.payload.errors;
          draft.newProcessID = action.payload.id;
          draft.createOverlayActive = true;
        } else {
          draft.createOverlayActive = false;
          draft.formData = INITIAL_STATE.formData;
        }
      });
    case actionTypes.ADD_PROCESSES_FAIL:
      return produce(state, (draft) => {
        draft.formError = action.payload;
      });
    case actionTypes.DELETE_PROCESS_OVERLAY:
      return produce(state, (draft) => {
        draft.deleteProcessOverlayActive = action.payload;
      });
    case actionTypes.PROCESS_TO_DELETE:
      return produce(state, (draft) => {
        draft.processToDeleteId = action.payload.id;
        draft.processToDeleteName = action.payload.name;
      });
    case actionTypes.SEARCH_DATA_SUCCESS:
      return produce(state, (draft) => {
        draft.searchFlow = true;
        draft.searchData = action.payload;
      });
    case actionTypes.RESET_SEARCH:
      return produce(state, (draft) => {
        draft.searchFlow = false;
        draft.searchData = INITIAL_STATE.searchData;
      });

    case actionTypes.DELETE_PROCESS_SUCCESSFUL:
      return produce(state, (draft) => {
        draft.deleteProcessSuccess = true;
        draft.testFail = true;
      });
    case actionTypes.SORT_BY_LAST_UPDATED:
      return produce(state, (draft) => {
        draft.sortByLastUpdatedOrder = action.payload;
      });
    default:
      return state;
  }
}
