import * as actionTypes from '../actions/actionTypes';
import produce from 'immer';

const INITIAL_STATE = {
  feedbackOverlayActive: false,
  feedbackData: {
    email: '',
    name: '',
    institution: '',
    process: '',
    feedback: '',
  },
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case actionTypes.FEEDBACK_OVERLAY:
      return produce(state, (draft) => {
        draft.feedbackOverlayActive = true;
      });
    case actionTypes.FEEDBACK_SUCCESSFUL:
      return produce(state, (draft) => {
        draft.feedbackOverlayActive = false;
      });
    default:
      return state;
  }
};
