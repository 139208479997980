import React from 'react';
import {Provider} from 'react-redux';
import {PersistGate} from 'redux-persist/integration/react';
import {store, history, persistor} from './redux/store';
import Router from './router';
import Toast from './components/common/Toast';
import {BrowserRouter} from 'react-router-dom';

const App = () => (
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <BrowserRouter onUpdate={() => window.scrollTo(0, 0)}>
        <Router history={history} />
        <Toast />
      </BrowserRouter>
    </PersistGate>
  </Provider>
);

export default App;
