import Global from './global_reducer';
import Home from './home_reducer';
import Search from './search_reducer';
import Tags from './tags_reducer';
import Processes from './processes_reducer';
import ProcessDetail from './process_detail_reducer';
import SearchProcessDetail from './search_process_detail_reducer';
import Properties from './properties_reducer';
import Feedback from './feedback_reducer';
import Pinboard from './pinboard_reducer';
import Filter from './filter_reducer';

export default {
  Global,
  Home,
  Search,
  Tags,
  Processes,
  ProcessDetail,
  SearchProcessDetail,
  Properties,
  Feedback,
  Pinboard,
  Filter,
};
