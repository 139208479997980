import {all} from 'redux-saga/effects';
import globalSaga from './global_saga';
import homeSaga from './home_saga';
import processDetailSaga from './process_detail_saga';
import processesSaga from './processes_saga';
import propertiesSaga from './properties_saga';
import searchProcessDetailSaga from './search_process_detail_saga';
import filterSaga from './filter_saga';

export default function* rootSaga() {
  yield all([
    globalSaga(),
    homeSaga(),
    processDetailSaga(),
    processesSaga(),
    propertiesSaga(),
    searchProcessDetailSaga(),
    filterSaga(),
  ]);
}
