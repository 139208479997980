import * as actionTypes from '../actions/actionTypes';
import produce from 'immer';

const INITIAL_STATE = {
  data: {
    data: [],
    process_name: '',
  },
  processDetailError: false,
  undoOverlayActive: false,
  undoToUndo: {},
  processIdToEdit: '',
  propertyIdToEdit: '',

  editAnswerOverlayActive: false,
  answerIdToEdit: '',
  answerToEdit: '',
  answerOptionsToEdit: [],
  answerQuestionTypeToEdit: '',

  editCommentOverlayActive: false,
  commentIdToEdit: '',
  commentToEdit: '',

  editReferencesOverlayActive: false,

  editNumberOfBodiesOverlayActive: false,
  numberOfBodiesToEdit: '',

  alternativeNamesOverlayActive: false,
  alternativeNamesToEdit: '',

  onUpdateSuccess: false,

  importOverlayActive: false,
  formData: {
    process_id: '',
    excel: '',
  },
  formError: {
    excel: null,
  },
};

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case actionTypes.LOAD_PROCESSDETAIL_SUCCESS:
      return produce(state, (draft) => {
        draft.onUpdateSuccess = false;
        draft.processDetailError = false;
        draft.data = action.data;
      });
    case actionTypes.LOAD_PROCESSDETAIL_FAIL:
      return produce(state, (draft) => {
        draft.processDetailError = true;
        draft.data = INITIAL_STATE.data;
      });
    case actionTypes.ANSWER_EDIT_OVERLAY:
      return produce(state, (draft) => {
        draft.editAnswerOverlayActive = action.payload;
        if (action.payload === false) {
          draft.formData = INITIAL_STATE.formData;
        }
      });
    case actionTypes.ANSWER_TO_EDIT:
      return produce(state, (draft) => {
        draft.answerIdToEdit = action.payload.id;
        draft.answerToEdit = action.payload.answer;
        draft.answerOptionsToEdit = action.payload.options;
        draft.answerQuestionTypeToEdit = action.payload.type;
        draft.processIdToEdit = action.payload.process;
        draft.propertyIdToEdit = action.payload.property;
      });
    case actionTypes.UPDATE_ANSWER_SUCCESSFUL:
      return produce(state, (draft) => {
        draft.editAnswerOverlayActive = false;
        draft.onUpdateSuccess = true;
      });
    case actionTypes.COMMENT_EDIT_OVERLAY:
      return produce(state, (draft) => {
        if (action.payload === false) {
          draft.editCommentOverlayActive = action.payload;
          draft.formData = INITIAL_STATE.formData;
        } else {
          draft.editCommentOverlayActive = action.payload;
        }
      });
    case actionTypes.COMMENT_TO_EDIT:
      return produce(state, (draft) => {
        draft.commentIdToEdit = action.payload.id;
        draft.commentToEdit = action.payload.comment;
      });
    case actionTypes.UPDATE_COMMENT_SUCCESSFUL:
      return produce(state, (draft) => {
        draft.editCommentOverlayActive = false;
        draft.onUpdateSuccess = true;
      });
    case actionTypes.REFERENCES_EDIT_OVERLAY:
      return produce(state, (draft) => {
        if (action.payload === false) {
          draft.editReferencesOverlayActive = action.payload;
          draft.formData = INITIAL_STATE.formData;
        } else {
          draft.editReferencesOverlayActive = action.payload;
        }
      });
    case actionTypes.REFEREMCES_TO_EDIT:
      return produce(state, (draft) => {
        draft.commentIdToEdit = action.payload.id;
        draft.commentToEdit = action.payload.comment;
      });
    case actionTypes.UPDATE_REFERENCES_SUCCESSFUL:
      return produce(state, (draft) => {
        draft.editReferencesOverlayActive = false;
        draft.onUpdateSuccess = true;
      });
    case actionTypes.NUMBER_OF_BODIES_EDIT_OVERLAY:
      return produce(state, (draft) => {
        if (action.payload === false) {
          draft.editNumberOfBodiesOverlayActive = action.payload;
          draft.formData = INITIAL_STATE.formData;
        } else {
          draft.editNumberOfBodiesOverlayActive = action.payload;
        }
      });
    case actionTypes.NUMBER_OF_BODIES_TO_EDIT:
      return produce(state, (draft) => {
        draft.processIdToEdit = action.payload.id;
        draft.numberOfBodiesToEdit = action.payload.value;
      });
    case actionTypes.UPDATE_NUMBER_OF_BODIES_SUCCESSFUL:
      return produce(state, (draft) => {
        draft.editNumberOfBodiesOverlayActive = false;
        draft.onUpdateSuccess = true;
      });

    case actionTypes.ALTERNATIVE_NAMES_OVERLAY:
      return produce(state, (draft) => {
        if (action.payload === false) {
          draft.alternativeNamesOverlayActive = action.payload;
          draft.formData = INITIAL_STATE.formData;
        } else {
          draft.alternativeNamesOverlayActive = action.payload;
        }
      });
    case actionTypes.ALTERNATIVE_NAMES_TO_EDIT:
      return produce(state, (draft) => {
        draft.processIdToEdit = action.payload.id;
        draft.alternativeNamesToEdit = action.payload.value;
      });
    case actionTypes.UPDATE_ALTERNATIVE_NAMES_SUCCESSFUL:
      return produce(state, (draft) => {
        draft.alternativeNamesOverlayActive = false;
        draft.onUpdateSuccess = true;
      });

    case actionTypes.UPDATE_IMPORT_EXCEL_FORM:
      return produce(state, (draft) => {
        draft.formData[action.payload.prop] = action.payload.value;
      });

    case actionTypes.IMPORT_OVERLAY:
      return produce(state, (draft) => {
        draft.importOverlayActive = action.payload;
      });

    case actionTypes.IMPORT_PROCESSES_SUCCESSFUL:
      return produce(state, (draft) => {
        draft.importOverlayActive = false;
      });

    case actionTypes.IMPORT_PROCESSES_FAILED:
      return produce(state, (draft) => {
        draft.formError = action.payload;
      });
    case actionTypes.UNDO_OVERLAY:
      return produce(state, (draft) => {
        draft.undoOverlayActive = action.payload;
      });
    case actionTypes.UNDO_EDIT:
      return produce(state, (draft) => {
        draft.undoOverlayActive = false;
        draft.onUpdateSuccess = true;
      });
    case actionTypes.UNDO_TO_UNDO:
      return produce(state, (draft) => {
        draft.undoToUndo = action.payload;
      });
    default:
      return state;
  }
}
