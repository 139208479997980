import * as actionTypes from './actionTypes';

export const loading = (status) => {
  return {
    type: actionTypes.SHOW_LOADING_SPINNER,
    payload: status,
  };
};

export const exportExcel = (id, processName = null) => {
  return {
    type: actionTypes.EXPORTING_EXCEL,
    id,
    processName,
  };
};

export const exportPDF = (data) => {
  return {
    type: actionTypes.EXPORT_TO_PDF,
    data,
  };
};

export const submitFeedbackOverlay = (value) => {
  return {
    type: actionTypes.SUBMIT_FEEDBACK_OVERLAY,
    payload: value,
  };
};

export const howItWorksOverlay = (value) => {
  return {
    type: actionTypes.HOW_IT_WORKS,
    payload: value,
  };
};

export const setActiveMultiselectDropdown = (label) => {
  return {
    type: actionTypes.ACTIVE_MULTISELECT_DROPDOWN,
    payload: label,
  };
};

export const setToken = (token) => {
  return {
    type: actionTypes.SET_TOKEN,
    token,
  };
};

export const clearToken = () => {
  return {
    type: actionTypes.CLEAR_TOKEN,
  };
};
