import axios from 'axios';
import { store } from './../redux/store';
import { loading } from '../redux/actions';

const APIHost = () => {
  let host = process.env.REACT_APP_BASEURL;
  return host;
};

const axiosInstance = axios.create({
  baseURL: APIHost(),
});

axiosInstance.interceptors.request.use(
  function (config) {
    const token = store.getState().god.token || null;

    if (token !== null) {
      config.headers.Authorization = `Bearer ${token} `;
    }
    return config;
  },
  function (error) {
    return Promise.reject(error);
  },
);

// Add a response interceptor
axiosInstance.interceptors.response.use(
  (response) => {
    console.log('Response', response);
    store.dispatch(loading(false));
    return response;
  },
  (error) => {
    console.log('Error', error.response);
    store.dispatch(loading(false));
    return Promise.reject(error);
  },
);

export default axiosInstance;
