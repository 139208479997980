import api from './../../utils/api';

const pathURL = 'filters';

export const getFilterList = () => {
  const url = `${pathURL}`;
  return api.get(url);
};

export const updateFilterList = (params) => {
  const url = `${pathURL}`;
  return api.post(url, params);
};
