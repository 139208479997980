import * as actionTypes from './actionTypes';

export const loadProcessDetail = (id = 0) => {
  return {
    type: actionTypes.LOAD_PROCESSDETAIL,
    id,
  };
};

export const loadProcessDetailSuccess = (data) => {
  return {
    type: actionTypes.LOAD_PROCESSDETAIL_SUCCESS,
    data,
  };
};

export const loadProcessDetailFail = () => {
  return {
    type: actionTypes.LOAD_PROCESSDETAIL_FAIL,
  };
};

export const editAnswerOverlay = (value) => {
  return {
    type: actionTypes.ANSWER_EDIT_OVERLAY,
    payload: value,
  };
};

export const setAnswerToEdit = (value) => {
  return {
    type: actionTypes.ANSWER_TO_EDIT,
    payload: value,
  };
};

export const saveAnswer = (data) => {
  return {
    type: actionTypes.UPDATE_ANSWER,
    data,
  };
};

export const saveAnswerSuccess = () => {
  return {
    type: actionTypes.UPDATE_ANSWER_SUCCESSFUL,
  };
};

export const saveAnswerFail = () => {
  return {
    type: actionTypes.UPDATE_ANSWER_FAIL,
  };
};
// EDIT ANSWER END

export const editCommentOverlay = (value) => {
  return {
    type: actionTypes.COMMENT_EDIT_OVERLAY,
    payload: value,
  };
};

export const setCommentToEdit = (value) => {
  return {
    type: actionTypes.COMMENT_TO_EDIT,
    payload: value,
  };
};

export const saveComment = (data) => {
  return {
    type: actionTypes.UPDATE_COMMENT,
    data,
  };
};

export const saveCommentSuccess = () => {
  return {
    type: actionTypes.UPDATE_COMMENT_SUCCESSFUL,
  };
};

export const saveCommentFail = () => {
  return {
    type: actionTypes.UPDATE_COMMENT_FAIL,
  };
};

// EDIT COMMENTS END

export const editReferencesOverlay = (value) => {
  return {
    type: actionTypes.REFERENCES_EDIT_OVERLAY,
    payload: value,
  };
};

export const setReferencesToEdit = (value) => {
  return {
    type: actionTypes.REFEREMCES_TO_EDIT,
    payload: value,
  };
};

export const saveReferences = (data) => {
  return {
    type: actionTypes.UPDATE_REFERENCES,
    data,
  };
};

export const saveReferencesSuccess = (data) => {
  return {
    type: actionTypes.UPDATE_REFERENCES_SUCCESSFUL,
    data,
  };
};

export const saveReferencesFail = (data) => {
  return {
    type: actionTypes.UPDATE_REFERENCES_FAIL,
    data,
  };
};

// EDIT REFERENCES END

export const editNumberOfBodiesOverlay = (value) => {
  return {
    type: actionTypes.NUMBER_OF_BODIES_EDIT_OVERLAY,
    payload: value,
  };
};

export const setNumberOfBodiesToEdit = (value) => {
  return {
    type: actionTypes.NUMBER_OF_BODIES_TO_EDIT,
    payload: value,
  };
};

export const saveNumberOfBodies = (data) => {
  return {
    type: actionTypes.UPDATE_NUMBER_OF_BODIES,
    data,
  };
};

export const saveNumberOfBodiesSuccess = (data) => {
  return {
    type: actionTypes.UPDATE_NUMBER_OF_BODIES_SUCCESSFUL,
    data,
  };
};

export const saveNumberOfBodiesFail = () => {
  return {
    type: actionTypes.UPDATE_NUMBER_OF_BODIES_FAIL,
  };
};

export const editAlternativeNamesOverlay = (value) => {
  return {
    type: actionTypes.ALTERNATIVE_NAMES_OVERLAY,
    payload: value,
  };
};

export const setAlternativeNamesToEdit = (value) => {
  return {
    type: actionTypes.ALTERNATIVE_NAMES_TO_EDIT,
    payload: value,
  };
};

export const saveAlternativeNames = (data) => {
  return {
    type: actionTypes.UPDATE_ALTERNATIVE_NAMES,
    data,
  };
};

export const saveAlternativeNamesSuccess = (data) => {
  return {
    type: actionTypes.UPDATE_ALTERNATIVE_NAMES_SUCCESSFUL,
    data,
  };
};

export const saveAlternativeNamesFail = () => {
  return {
    type: actionTypes.UPDATE_ALTERNATIVE_NAMES_FAIL,
  };
};

export const updateImportExcelForm = ({prop, value}) => {
  return {
    type: actionTypes.UPDATE_IMPORT_EXCEL_FORM,
    payload: {prop, value},
  };
};

export const importOverlay = (value) => {
  return {
    type: actionTypes.IMPORT_OVERLAY,
    payload: value,
  };
};

export const importExcel = (data, file) => {
  return {
    type: actionTypes.IMPORT_PROCESSES,
    data,
    file,
  };
};

export const importExcelSuccess = () => {
  return {
    type: actionTypes.IMPORT_PROCESSES_SUCCESSFUL,
  };
};

export const importExcelFail = (data) => {
  return {
    type: actionTypes.IMPORT_PROCESSES_FAILED,
    payload: data,
  };
};

export const setUndo = ({type, id}) => {
  return {
    type: actionTypes.UNDO_TO_UNDO,
    payload: {type, id},
  };
};

export const undo = (data) => {
  return {
    type: actionTypes.UNDO_EDIT,
    data,
  };
};

export const undoSuccess = () => {
  return {
    type: actionTypes.UNDO_EDIT_SUCCESS,
  };
};

export const undoFail = () => {
  return {
    type: actionTypes.UNDO_EDIT_FAIL,
  };
};

export const undoOverlay = (value) => {
  return {
    type: actionTypes.UNDO_OVERLAY,
    payload: value,
  };
};
