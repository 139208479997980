import * as actionTypes from './actionTypes';

export const getFilterList = (data) => {
  return {
    type: actionTypes.GET_FILTER_LIST,
    data,
  };
};

export const getFilterListSuccess = (data) => {
  return {
    type: actionTypes.GET_FILTER_LIST_SUCCESS,
    data,
  };
};

export const getFilterListFail = () => {
  return {
    type: actionTypes.GET_FILTER_LIST_FAIL,
  };
};

export const updateFilterList = (data) => {
  return {
    type: actionTypes.UPDATE_FILTER_LIST,
    data,
  };
};

export const updateFilterListSuccess = (data) => {
  return {
    type: actionTypes.UPDATE_FILTER_LIST_SUCCESS,
    data,
  };
};

export const updateFilterListFail = () => {
  return {
    type: actionTypes.UPDATE_FILTER_LIST_FAIL,
  };
};
