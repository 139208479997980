import * as actionTypes from './actionTypes';

export const loadJsonOptions = () => {
  return {
    type: actionTypes.LOAD_JSON_FILE,
  };
};

export const loadJsonOptionsSuccess = (data) => {
  return {
    type: actionTypes.LOAD_JSON_FILE_SUCCESS,
    payload: data,
  };
};

export const loadJsonOptionsFail = () => {
  return {
    type: actionTypes.LOAD_JSON_FILE_FAIL,
  };
};

export const searchParamsChanged = (value, field, subfield) => {
  return {
    type: actionTypes.SEARCH_PARAMS_CHANGED,
    payload: {field, subfield, value},
  };
};

export const submitSearchParams = (data) => {
  return {
    type: actionTypes.SUBMIT_SEARCH_PARAMS,
    data,
  };
};

export const submitSearchParamsSuccess = (data) => {
  return {
    type: actionTypes.SUBMIT_SEARCH_PARAMS_SUCCESS,
    data,
  };
};

export const submitSearchParamsFail = () => {
  return {
    type: actionTypes.SUBMIT_SEARCH_PARAMS_FAIL,
  };
};

export const setFilterCleared = (payload) => {
  return {
    type: actionTypes.SET_FILTERS_CLEARED,
    payload,
  };
};

export const clearAllFilters = () => {
  return {
    type: actionTypes.CLEAR_ALL_FILTERS,
  };
};

export const removeSearchResult = (process) => {
  return {
    type: actionTypes.REMOVE_SEARCH_RESULT,
    process,
  };
};

export const undoRemovedSearchResult = (id) => {
  return {
    type: actionTypes.UNDO_REMOVED_SEARCH_RESULT,
    id,
  };
};
