import * as actionTypes from '../actions/actionTypes';
import produce from 'immer';

const INITIAL_STATE = {
  search_results: [],
  searchSuccess: false,
  exportToPDFOverlayActive: false,
  exportToPDFId: '',
  exportToPDFName: '',
  filtersCleared: true,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case actionTypes.SUBMIT_SEARCH_PARAMS_SUCCESS:
      return produce(state, (draft) => {
        draft.search_results = action.data;
        draft.searchSuccess = true;
      });
    case actionTypes.SUBMIT_SEARCH_PARAMS_FAIL:
      return produce(state, (draft) => {
        draft.searchSuccess = false;
      });
    case actionTypes.RESET_SEARCH_STATUS:
      return produce(state, (draft) => {
        draft.searchSuccess = false;
      });
    case actionTypes.EXPORT_TO_PDF_OVERLAY:
      return produce(state, (draft) => {
        draft.exportToPDFOverlayActive = action.payload.value;
        draft.exportToPDFId = action.payload.id;
        draft.exportToPDFName = action.payload.name;
      });
    case actionTypes.SET_FILTERS_CLEARED:
      return produce(state, (draft) => {
        draft.filtersCleared = action.payload;
      });

    default:
      return state;
  }
};
