import { all, takeEvery, put, fork } from 'redux-saga/effects';

import * as actionTypes from '../actions/actionTypes';
import * as actions from '../actions/index';
import * as API from '../api';

export function* loadProcesses() {
  yield takeEvery(actionTypes.LOAD_PROCESSES, function* (action) {
    yield put(actions.loading(true));

    try {
      const response = yield API.loadProcesses(action.data);

      if (response.data) {
        yield put(
          actions.loadProcessesSuccess(
            response.data.data,
            response.data.offset,
          ),
        );
      } else {
        yield put(actions.loadProcessesFail());
      }
      yield put(actions.loading(false));
    } catch (error) {
      yield put(actions.loadProcessesFail());
      yield put(actions.loading(false));
    }
  });
}

export function* addNewProccess() {
  yield takeEvery(actionTypes.CREATE_PROCESSES_LOADING, function* (action) {
    yield put(actions.loading(true));

    try {
      const response = yield API.addNewProccess(action.data);

      if (response.status === 200) {
        yield put(
          actions.addNewProccessSuccess(
            response?.data
          ),
        );
      } else {
        yield put(actions.addNewProccessFail());
      }
      yield put(actions.loading(false));
    } catch (error) {
      yield put(actions.addNewProccessFail());
      yield put(actions.loading(false));
    }
  });
}

export function* deleteProcess() {
  yield takeEvery(actionTypes.DELETE_PROCESS, function* (action) {
    yield put(actions.loading(true));

    try {
      const response = yield API.deleteProcess(action.data);

      if (response.data) {
        yield put(actions.deleteProcessSuccess());
      } else {
        yield put(actions.deleteProcessFail());
      }
      yield put(actions.loading(false));
    } catch (error) {
      yield put(actions.deleteProcessFail());
      yield put(actions.loading(false));
    }
  });
}

export function* searchProcess() {
  yield takeEvery(actionTypes.SEARCH_DATA, function* (action) {
    yield put(actions.loading(true));

    try {
      const response = yield API.searchProcess(action.data);

      if (response.data) {
        yield put(actions.searchProcessSuccess(response.data));
      } else {
        yield put(actions.searchProcessFail());
      }
      yield put(actions.loading(false));
    } catch (error) {
      yield put(actions.searchProcessFail());
      yield put(actions.loading(false));
    }
  });
}

export default function* processesSaga() {
  yield all([
    fork(loadProcesses),
    fork(addNewProccess),
    fork(deleteProcess),
    fork(searchProcess),
  ]);
}
