import {all, takeEvery, put, fork} from 'redux-saga/effects';

import * as actionTypes from '../actions/actionTypes';
import * as actions from '../actions/index';
import * as API from '../api';

export function* getFilterList() {
  yield takeEvery(actionTypes.GET_FILTER_LIST, function* (action) {
    yield put(actions.loading(true));

    try {
      const response = yield API.getFilterList();

      if (response.data) {
        yield put(actions.getFilterListSuccess(response.data));
      } else {
        yield put(actions.getFilterListFail());
      }
      yield put(actions.loading(false));
    } catch (error) {
      yield put(actions.getFilterListFail());
      yield put(actions.loading(false));
    }
  });
}

export function* updateFilterList() {
  yield takeEvery(actionTypes.UPDATE_FILTER_LIST, function* (action) {
    yield put(actions.loading(true));

    try {
      const response = yield API.updateFilterList(action.data);

      if (response.data) {
        yield put(actions.updateFilterListSuccess(response.data));
      } else {
        yield put(actions.updateFilterListFail());
      }
      yield put(actions.loading(false));
    } catch (error) {
      yield put(actions.updateFilterListFail());
      yield put(actions.loading(false));
    }
  });
}

export default function* filterSaga() {
  yield all([fork(getFilterList), fork(updateFilterList)]);
}
