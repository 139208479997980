import * as actionTypes from '../actions/actionTypes';
import produce from 'immer';

const INITIAL_STATE = {
  onDeleteSuccess: false,
  data: [],
  createOverlayActive: false,
  createPropertyLoading: false,
  updateSortLoading: false,
  formData: {
    codes_kzf: '',
    question: '',
    variable_name: '',
    category_id: 1,
    topic_name: '',
    question_type: 1,
    answers: [''],
  },
  formError: {},
  searchFlow: false,
  searchData: [],
};

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case actionTypes.LOAD_PROPERTIES_SUCCESS:
      return produce(state, (draft) => {
        draft.onDeleteSuccess = false;
        draft.data = action.payload;
      });

    case actionTypes.DELETE_PROPERTY_SUCCESS:
      return produce(state, (draft) => {
        draft.onDeleteSuccess = true;
      });

    case actionTypes.PROPERTY_CREATE_OVERLAY:
      return produce(state, (draft) => {
        if (action.payload === false) {
          draft.createOverlayActive = action.payload;
          draft.formData = INITIAL_STATE.formData;
        } else {
          draft.createOverlayActive = action.payload;
        }
      });

    case actionTypes.UPDATE_CREATEPROPERTY_FORM:
      return produce(state, (draft) => {
        draft.formData[action.payload.prop] = action.payload.value;
      });

    case actionTypes.CREATE_PROPERTY_LOADING:
      return produce(state, (draft) => {
        draft.createPropertyLoading = true;
      });

    case actionTypes.ADD_PROPERTY_SUCCESSFUL:
      return produce(state, (draft) => {
        draft.createOverlayActive = false;
        draft.formData = INITIAL_STATE.formData;
        draft.formError = {};
        draft.createPropertyLoading = false;
      });

    case actionTypes.ADD_PROPERTY_FAIL:
      return produce(state, (draft) => {
        draft.formError = action.payload;
      });

    case actionTypes.PROPERTY_EDIT_FORM_SUCCESS:
      return produce(state, (draft) => {
        draft.formData = action.payload;
      });
    case actionTypes.PROPERTY_SORT:
      return produce(state, (draft) => {
        draft.updateSortLoading = action.payload;
      });

    case actionTypes.SEARCH_DATA_PROPERTIES_SUCCESS:
      return produce(state, (draft) => {
        draft.searchFlow = true;
        draft.searchData = action.payload;
      });
    case actionTypes.RESET_SEARCH:
      return produce(state, (draft) => {
        draft.searchFlow = false;
        draft.searchData = INITIAL_STATE.searchData;
      });

    case actionTypes.ACTIVE_PROPERTY_SUCCESS:
      return produce(state, (draft) => {
        const {category_index, property_index, active} = action.data;
        draft.data[category_index].properties[property_index].active = active;
      });
    default:
      return state;
  }
}
