import {all, takeEvery, put, fork} from 'redux-saga/effects';

import * as actionTypes from '../actions/actionTypes';
import * as actions from '../actions/index';
import * as API from '../api';

export function* loadProcessDetail() {
  yield takeEvery(actionTypes.LOAD_PROCESSDETAIL, function* (action) {
    yield put(actions.loading(true));

    try {
      const response = yield API.loadProcessDetail(action.id);

      if (response) {
        yield put(actions.loadProcessDetailSuccess(response.data));
      } else {
        yield put(actions.loadProcessDetailFail());
      }
      yield put(actions.loading(false));
    } catch (error) {
      yield put(actions.loadProcessDetailFail());
      yield put(actions.loading(false));
    }
  });
}

export function* saveAnswer() {
  yield takeEvery(actionTypes.UPDATE_ANSWER, function* (action) {
    yield put(actions.loading(true));

    try {
      const response = yield API.saveAnswer(action.data);

      if (response.data) {
        yield put(actions.saveAnswerSuccess());
      } else {
        yield put(actions.saveAnswerFail());
      }
      yield put(actions.loading(false));
    } catch (error) {
      yield put(actions.saveAnswerFail());
      yield put(actions.loading(false));
    }
  });
}

export function* saveComment() {
  yield takeEvery(actionTypes.UPDATE_COMMENT, function* (action) {
    yield put(actions.loading(true));

    try {
      const response = yield API.saveComment(action.data);

      if (response.data) {
        yield put(actions.saveCommentSuccess());
      } else {
        yield put(actions.saveCommentFail());
      }
      yield put(actions.loading(false));
    } catch (error) {
      yield put(actions.saveCommentFail());
      yield put(actions.loading(false));
    }
  });
}

export function* saveReferences() {
  yield takeEvery(actionTypes.UPDATE_REFERENCES, function* (action) {
    yield put(actions.loading(true));

    try {
      const response = yield API.saveReferences(action.data);

      if (response.data) {
        yield put(actions.saveReferencesSuccess());
      } else {
        yield put(actions.saveReferencesFail());
      }
      yield put(actions.loading(false));
    } catch (error) {
      yield put(actions.saveReferencesFail());
      yield put(actions.loading(false));
    }
  });
}

export function* saveNumberOfBodies() {
  yield takeEvery(actionTypes.UPDATE_NUMBER_OF_BODIES, function* (action) {
    yield put(actions.loading(true));

    try {
      const response = yield API.saveNumberOfBodies(action.data);

      if (response.data) {
        yield put(actions.saveNumberOfBodiesSuccess());
      } else {
        yield put(actions.saveNumberOfBodiesFail());
      }
      yield put(actions.loading(false));
    } catch (error) {
      yield put(actions.saveNumberOfBodiesFail());
      yield put(actions.loading(false));
    }
  });
}

export function* saveAlternativeNames() {
  yield takeEvery(actionTypes.UPDATE_ALTERNATIVE_NAMES, function* (action) {
    yield put(actions.loading(true));

    try {
      const response = yield API.saveAlternativeNames(action.data);

      if (response.data) {
        yield put(actions.saveAlternativeNamesSuccess());
      } else {
        yield put(actions.saveAlternativeNamesFail());
      }
      yield put(actions.loading(false));
    } catch (error) {
      yield put(actions.saveAlternativeNamesFail());
      yield put(actions.loading(false));
    }
  });
}

export function* importExcel() {
  yield takeEvery(actionTypes.IMPORT_PROCESSES, function* (action) {
    yield put(actions.loading(true));

    try {
      const response = yield API.importExcel(action.data);

      if (response.data) {
        yield put(actions.importExcelSuccess());
      } else {
        yield put(actions.importExcelFail());
      }
      yield put(actions.loading(false));
    } catch (error) {
      yield put(actions.importExcelFail());
      yield put(actions.loading(false));
    }
  });
}

export function* undo() {
  yield takeEvery(actionTypes.UNDO_EDIT, function* (action) {
    yield put(actions.loading(true));

    try {
      const response = yield API.undo(action.data);

      if (response.data) {
        yield put(actions.undoSuccess());
      } else {
        yield put(actions.undoFail());
      }
      yield put(actions.loading(false));
    } catch (error) {
      yield put(actions.undoFail());
      yield put(actions.loading(false));
    }
  });
}

export default function* processDetailSaga() {
  yield all([
    fork(loadProcessDetail),
    fork(saveAnswer),
    fork(saveComment),
    fork(saveReferences),
    fork(saveNumberOfBodies),
    fork(saveAlternativeNames),
    fork(importExcel),
    fork(undo),
  ]);
}
