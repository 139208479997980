import * as actionTypes from './actionTypes';

export const loadSearchProcessDetail = (id = 0) => {
  return {
    type: actionTypes.LOAD_SEARCH_PROCESSDETAIL,
    id,
  };
};

export const loadSearchProcessDetailSuccess = (data) => {
  return {
    type: actionTypes.LOAD_SEARCH_PROCESSDETAIL_SUCCESS,
    payload: data,
  };
};

export const loadSearchProcessDetailFail = () => {
  return {
    type: actionTypes.LOAD_SEARCH_PROCESSDETAIL_FAIL,
  };
};

export const refreshSearchProcessDetail = (status, id) => {
  return {
    type: actionTypes.REFRESH_SEARCH_PROCESSDETAIL,
    payload: {status, id},
  };
};
