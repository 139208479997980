import * as actionTypes from '../actions/actionTypes';
import produce from 'immer';

const INITIAL_STATE = {
  data: {
    data: [],
    process_name: '',
  },
  refreshData: false,
  refreshDataId: '',
  categoryToolData: [
    "General details related to the context in which the process developed",
    "Deliberations that pre-date the start of the constitution-building process",
    "Process that concludes with the adoption of an interim political arrangement or constitution",
    "Preparation of the initial constitutional draft, or a version of the latter",
    "Part of the process that concludes with the adoption of the final draft",
    "Public involvement throughout the constitution-making process",
    "Post-adoption review and ratification of the constitutional draft",
    "Moment in time when the constitution starts to be implemented",
    "Post-adoption constitutional amendments",
  ],
};

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case actionTypes.LOAD_SEARCH_PROCESSDETAIL_SUCCESS:
      return produce(state, (draft) => {
        draft.data = action.payload;
      });
    case actionTypes.REFRESH_SEARCH_PROCESSDETAIL:
      return produce(state, (draft) => {
        draft.refreshData = action.payload.status;
        draft.refreshDataId = action.payload.id;
      });
    // case actionTypes.CHANGE_CATEGORTY_TOOLTIP:
    //   return produce(state, (draft) => {
    //     draft.categoryToolData = action.payload;
    //   });
    default:
      return state;
  }
}
