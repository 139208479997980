import * as actionTypes from '../actions/actionTypes';
import produce from 'immer';

const INITIAL_STATE = {
  filterList: {
    data: {
      filters: [],
      stages: [],
    },
  },
};

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case actionTypes.GET_FILTER_LIST_SUCCESS:
      return produce(state, (draft) => {
        draft.filterList.data = action.data;
      });

    default:
      return state;
  }
}
