import {createStore, combineReducers, applyMiddleware, compose} from 'redux';
import {persistStore, persistReducer} from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import thunk from 'redux-thunk';
import createSagaMiddleware from 'redux-saga';
import logger from 'redux-logger';

import {createBrowserHistory} from 'history';
import {
  createReduxHistoryContext,
  // reachify
} from 'redux-first-history';

import reducers from './reducers';
import sagas from './sagas';

const {
  createReduxHistory,
  routerMiddleware,
  routerReducer,
} = createReduxHistoryContext({
  history: createBrowserHistory(),
});

const sagaMiddleware = createSagaMiddleware();

let middlewares = [thunk, sagaMiddleware, routerMiddleware];
if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
  // dev code
  middlewares = [thunk, sagaMiddleware, routerMiddleware, logger];
}

const rootPersistConfig = {
  key: 'root',
  storage,
  whitelist: ['pinboard'],
};

const globalPersistConfig = {
  key: 'god',
  storage,
  whitelist: ['token'],
};

const rootReducer = combineReducers({
  router: routerReducer,
  god: persistReducer(globalPersistConfig, reducers.Global),
  home: reducers.Home,
  search: reducers.Search,
  tags: reducers.Tags,
  searchProcessDetail: reducers.SearchProcessDetail,
  processes: reducers.Processes,
  processDetail: reducers.ProcessDetail,
  properties: reducers.Properties,
  feedback: reducers.Feedback,
  pinboard: reducers.Pinboard,
  filter: reducers.Filter,
});

const persistedReducer = persistReducer(rootPersistConfig, rootReducer);

const store = createStore(
  persistedReducer,
  undefined,
  compose(applyMiddleware(...middlewares)),
);
sagaMiddleware.run(sagas);

const persistor = persistStore(store);

const history = createReduxHistory(store);

export {store, persistor, history};
