import api from './../../utils/api';

export const loadProcessDetail = (id) => {
  const url = `processes/view/${id}`;
  return api.get(url);
};

export const saveAnswer = (params) => {
  const url = `properties/answer/update`;
  return api.post(url, params);
};

export const saveComment = (params) => {
  const url = `properties/comments/update`;
  return api.post(url, params);
};

export const saveReferences = (params) => {
  const url = `properties/references/update`;
  return api.post(url, params);
};

export const saveNumberOfBodies = (params) => {
  const url = `processes/update/bodies`;
  return api.post(url, params);
};

export const saveAlternativeNames = (params) => {
  const url = `processes/country`;
  return api.post(url, params);
};

export const importExcel = (params) => {
  return api.post('processes/update', params, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

export const undo = (params) => {
  return api.post('properties/undo', params);
};
