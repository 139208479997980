import * as actionTypes from './actionTypes';

export const resetSearchStatus = (text) => {
  return {
    type: actionTypes.RESET_SEARCH_STATUS,
    payload: false,
  };
};

export const exportToPDFOverlay = (value, id, name) => {
  return {
    type: actionTypes.EXPORT_TO_PDF_OVERLAY,
    payload: {value, id, name},
  };
};

export const clearAllFilters = () => {
  return {
    type: actionTypes.CLEAR_ALL_FILTERS,
  };
};
