import React, { Component } from 'react';
import Loader from 'react-loader-spinner';
import { connect } from 'react-redux';

class LoadingSpinner extends Component {
    render() {
		return (
			<div className="loader-spinner">
				{
					this.props.showLoadingSpinner &&
						<div className="overlay-wrapper" style={{ backgroundColor: 'rgba(255, 255, 255, 0.7)' }}>
							<div className="hlp-fh hlp-flex flex-aic flex-jcc">
								<Loader type="Grid" color="midnightblue" height={60} width={60} />
							</div>
						</div>
				}
			</div>
		);
    }
}

function mapStateToProps(state) {
  const {
    showLoadingSpinner
  } = state.god;


  return {
    showLoadingSpinner
  };
}

export default connect(mapStateToProps)(LoadingSpinner);
// export { LoadingSpinner };
