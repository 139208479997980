import api from './../../utils/api';

export const loadProperties = () => {
  const url = 'v2/properties';
  return api.get(url);
};

export const deleteProperty = (id) => {
  const url = `properties/delete/${id}`;
  return api.get(url);
};

export const addNewProperty = (params) => {
  const url = 'properties/store';
  return api.post(url, params);
};

export const editProperties = (id) => {
  const url = `properties/edit/${id}`;
  return api.get(url);
};

export const sortProperties = (params) => {
  const url = 'properties/sort';
  return api.post(url, params);
};

export const searchProperty = (params) => {
  const url = 'properties/search';
  return api.post(url, params);
};

export const activeProperty = (params) => {
  const url = 'properties/active';
  return api.post(url, params);
};
