import { all, takeEvery, put, fork } from 'redux-saga/effects';

import * as actionTypes from '../actions/actionTypes';
import * as actions from '../actions/index';
import * as API from '../api';
import { toast } from 'react-toastify';
import { _searchParamCount } from '../../utils/helper';

export function* loadJsonOptions() {
  yield takeEvery(actionTypes.LOAD_JSON_FILE, function* () {
    yield put(actions.loading(true));

    try {
      const response = yield API.loadJsonOptions();
      console.log('loadjson ', response);
      if (response.data) {
        yield put(actions.loadJsonOptionsSuccess(response.data));
      } else {
        console.log('Warning: Fail to load json values. ' + response);
        yield put(actions.loadJsonOptionsFail());
      }
      yield put(actions.loading(false));
    } catch (error) {
      yield put(actions.loadJsonOptionsFail());
      yield put(actions.loading(false));
    }
  });
}

export function* submitSearchParams() {
  yield takeEvery(actionTypes.SUBMIT_SEARCH_PARAMS, function* (action) {
    yield put(actions.loading(true));

    try {
      const paramsCount = yield _searchParamCount(action.data);

      if (paramsCount === 0) {
        yield put(actions.setFilterCleared(true));
        yield put(actions.clearAllFilters());
      } else {
        yield put(actions.setFilterCleared(false));
      }

      yield put(actions.resetSearchStatus());

      const response = yield API.submitSearchParams(action.data, paramsCount);

      if (response.data) {
        yield put(actions.submitSearchParamsSuccess(response.data));
      } else {
        console.log('Warning: Fail to load json values. ' + response);
        yield put(actions.submitSearchParamsFail());
      }
      yield put(actions.loading(false));
    } catch (error) {
      toast.error(
        "Oops. There don't seem to be any results for your combination. Please try a different request.",
      );
      yield put(actions.submitSearchParamsFail());
      yield put(actions.loading(false));
    }
  });
}

export default function* homeSaga() {
  yield all([fork(loadJsonOptions), fork(submitSearchParams)]);
}
