import {all, takeEvery, put, fork} from 'redux-saga/effects';

import * as actionTypes from '../actions/actionTypes';
import * as actions from '../actions/index';
import * as API from '../api';

export function* loadProperties() {
  yield takeEvery(actionTypes.LOAD_PROPERTIES, function* () {
    yield put(actions.loading(true));

    try {
      const response = yield API.loadProperties();

      if (response.data) {
        yield put(actions.loadPropertiesSuccess(response.data));
      } else {
        yield put(actions.loadPropertiesFail());
      }
      yield put(actions.loading(false));
    } catch (error) {
      yield put(actions.loadPropertiesFail());
      yield put(actions.loading(false));
    }
  });
}

export function* addNewProperty() {
  yield takeEvery(actionTypes.ADD_PROPERTY, function* (action) {
    yield put(actions.loading(true));

    try {
      const response = yield API.addNewProperty(action.data);

      if (response.data) {
        yield put(actions.addNewPropertySuccess());
        yield put(actions.loadProperties());
      } else {
        yield put(actions.addNewPropertyFail());
      }
      yield put(actions.loading(false));
    } catch (error) {
      yield put(actions.addNewPropertyFail());
      yield put(actions.loading(false));
    }
  });
}

export function* editProperties() {
  yield takeEvery(actionTypes.PROPERTY_EDIT_FORM, function* (action) {
    yield put(actions.loading(true));

    try {
      const response = yield API.editProperties(action.id);

      if (response.data) {
        yield put(actions.editPropertiesSuccess(response.data));
      } else {
        yield put(actions.editPropertiesFail());
      }
      yield put(actions.loading(false));
    } catch (error) {
      yield put(actions.editPropertiesFail());
      yield put(actions.loading(false));
    }
  });
}

export function* deleteProperty() {
  yield takeEvery(actionTypes.DELETE_PROPERTY, function* (action) {
    yield put(actions.loading(true));

    try {
      const response = yield API.deleteProperty(action.id);

      if (response.data) {
        yield put(actions.deletePropertySuccess());
      } else {
        yield put(actions.deletePropertyFail());
      }
      yield put(actions.loading(false));
    } catch (error) {
      yield put(actions.deletePropertyFail());
      yield put(actions.loading(false));
    }
  });
}

export function* sortProperties() {
  yield takeEvery(actionTypes.PROPERTY_SORT, function* (action) {
    yield put(actions.loading(true));

    try {
      const response = yield API.sortProperties(action.data);

      if (response.data) {
        yield put(actions.sortPropertiesSuccess());
        yield put(actions.loadProperties());
      }
      yield put(actions.loading(false));
    } catch (error) {
      yield put(actions.loading(false));
    }
  });
}

export function* searchProperty() {
  yield takeEvery(actionTypes.SEARCH_DATA_PROPERTIES, function* (action) {
    yield put(actions.loading(true));

    try {
      const response = yield API.searchProperty(action.data);

      if (response.data) {
        yield put(actions.searchPropertySuccess(response.data));
      } else {
        yield put(actions.searchPropertyFail());
      }
      yield put(actions.loading(false));
    } catch (error) {
      yield put(actions.searchPropertyFail());
      yield put(actions.loading(false));
    }
  });
}

export function* activeProperty() {
  yield takeEvery(actionTypes.ACTIVE_PROPERTY, function* (action) {
    yield put(actions.loading(true));

    try {
      const response = yield API.activeProperty(action.data);

      if (response.status === 200) {
        yield put(actions.activePropertySuccess(action.data));
      } else {
        yield put(actions.activePropertyFail());
      }
      yield put(actions.loading(false));
    } catch (error) {
      yield put(actions.activePropertyFail());
      yield put(actions.loading(false));
    }
  });
}

export default function* processesSaga() {
  yield all([
    fork(loadProperties),
    fork(addNewProperty),
    fork(editProperties),
    fork(deleteProperty),
    fork(sortProperties),
    fork(searchProperty),
    fork(activeProperty),
  ]);
}
