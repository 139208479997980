import * as actionTypes from './actionTypes';

export const removeTag = (value, field, subfield) => {
  // console.log('removeTag', ' | ', value, ' | ', field, ' | ', subfield);

  return (dispatch) => {
    dispatch({
      type: actionTypes.SEARCH_PARAMS_CHANGED,
      payload: {field, subfield, value},
    });

    dispatch({
      type: actionTypes.TAG_REMOVED,
      payload: true,
    });
  };
};

export const tagRemovedReset = () => {
  return {
    type: actionTypes.TAG_REMOVED_RESET,
  };
};
