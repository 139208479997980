import api from './../../utils/api';

export const loadJsonOptions = () => {
  const url = '/json/SearchOptions.json';
  return api.get(url, {
    baseURL: process.env.REACT_APP_URL,
  });
};

export const submitSearchParams = (params, count) => {
  let url = 'search';

  if (params === 'all' || count === 0) {
    url = 'search/all';
  }

  return api.post(url, params);
};
