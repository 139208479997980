import * as actionTypes from '../actions/actionTypes';
import produce from 'immer';

const INITIAL_STATE = {
  tagRemoved: false,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case actionTypes.TAG_REMOVED:
      return produce(state, (draft) => {
        draft.tagRemoved = action.payload;
      });
    case actionTypes.TAG_REMOVED_RESET:
      return produce(state, (draft) => {
        draft.tagRemoved = false;
      });
    default:
      return state;
  }
};
