import React, {Suspense, lazy, useEffect} from 'react';
import {Switch, Route, Redirect} from 'react-router-dom';
import {ConnectedRouter} from 'connected-react-router';

// Redux
import {connect} from 'react-redux';

// UI Component
import Loader from './components/common/LoadingSpinner';
import ResolutionNotSupport from './components/pages/ResolutionNotSupport';

// Public Container
const SearchProcessDetail = lazy(() =>
  import('./components/pages/SearchProcessDetail'),
);
const Home = lazy(() => import('./components/pages/Home'));
const About = lazy(() => import('./components/pages/About'));
const Search = lazy(() => import('./components/pages/Search'));

// Admin Container
const Login = lazy(() => import('./components/pages/admin/Login'));
const Processes = lazy(() => import('./components/pages/admin/Processes'));
const ProcessDetail = lazy(() =>
  import('./components/pages/admin/ProcessDetail'),
);
const Properties = lazy(() => import('./components/pages/admin/Properties'));
const Filters = lazy(() => import('./components/pages/admin/Filters'));
const NotFound = lazy(() => import('./components/pages/admin/NotFound'));

const AdminSwitch = () => {
  return (
    <Switch>
      <Route exact path="/" render={() => <Redirect to="/processes" />} />
      <Route exact path="/processes" component={Processes} />
      <Route exact path="/search/:query" component={Processes} />
      <Route path="/process/:id/:type?/:typeid?" component={ProcessDetail} />
      <Route exact path="/properties" component={Properties} />
      <Route exact path="/property/:query" component={Properties} />
      <Route exact path="/filters" component={Filters} />
      <Route path="*" render={(props) => <NotFound {...props} />} />
    </Switch>
  );
};

const PublicSwitch = () => {
  return (
    <Switch>
      <Route exact path="/" component={Home} />
      <Route exact path="/search" component={Search} />
      <Route exact path="/about" component={About} />
      <Route path="/search/:all" component={Search} />
      <Route
        path="/process/:id/:stage?/:stageId?/:property?/:propertyId?"
        component={SearchProcessDetail}
      />
      <Route exact path="/about" component={About} />
      <Route exact path="/login" component={Login} />
      <Redirect to="/" />
    </Switch>
  );
};

const AppRouter = (props) => {
  const roleSwitch = () => {
    if (props.token !== null) return <AdminSwitch />;

    return <PublicSwitch />;
  };

  useEffect(() => {
    if (props.token !== null) document.body.classList.add('admin');
    return () => {
      document.body.classList.remove('admin');
    };
  }, [props.token]);

  const {history} = props;

  return (
    <Suspense fallback={<Loader />}>
      <ResolutionNotSupport />
      <ConnectedRouter history={history}>{roleSwitch()}</ConnectedRouter>
    </Suspense>
  );
};

const mapStateToProps = (state) => {
  return {
    token: state.god.token,
  };
};

export default connect(mapStateToProps)(AppRouter);
