import api from './../../utils/api';

export const exportExcel = (id) => {
  const url = `export/excel/pinboard`;
  return api.post(
    url,
    {id},
    {
      baseURL: process.env.REACT_APP_BASEURL,
      responseType: 'blob',
    },
  );
};

export const exportPDF = (params) => {
  const url = `exportpdf`;
  return api.post(url, params, {
    baseURL: process.env.REACT_APP_BASEURL,
    responseType: 'blob',
  });
};
