import * as actionTypes from './actionTypes';

export const pinProcess = (id, name) => {
  return {
    type: actionTypes.PIN_PROCESS,
    payload: {id, name},
  };
};

export const pinStage = (id, name, process_id, process_name) => {
  return {
    type: actionTypes.PIN_STAGE,
    payload: {id, name, process_id, process_name},
  };
};

export const pinProperty = (
  id,
  name,
  process_id,
  process_name,
  stage_id,
  stage_name,
) => {
  return {
    type: actionTypes.PIN_PROPERTY,
    payload: {id, name, process_id, process_name, stage_id, stage_name},
  };
};

export const pinboardOverlay = (value) => {
  return {
    type: actionTypes.PINBOARD_OVERLAY,
    payload: value,
  };
};

export const clearPinboard = () => {
  return {
    type: actionTypes.CLEAR_PINBOARD,
  };
};

export const pinStagesOverlay = (value) => {
  return {
    type: actionTypes.PIN_STAGES_OVERLAY,
    payload: value,
  };
};
