import api from './../../utils/api';

export const loadProcesses = (params) => {
  const url = `processes/i/${params.offset}/${params.sort}`;
  return api.get(url);
};

export const addNewProccess = (params) => {
  const url = 'processes/store';
  return api.post(url, params, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

export const deleteProcess = (data) => {
  const url = `processes/delete`;
  return api.post(url, { data });
};

export const searchProcess = (params) => {
  const url = `processes/search`;
  return api.post(url, params);
};
