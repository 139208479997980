import * as actionTypes from '../actions/actionTypes';
import produce from 'immer';

const INITIAL_STATE = {
  showLoadingSpinner: false,
  submitFeedbackOverlayActive: false,
  howItWorksActive: false,

  exportingInProgress: false,
  activeMultiselectDropdown: '',
  token: null,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case actionTypes.SET_TOKEN:
      return produce(state, (draft) => {
        draft.token = action.token;
      });
    case actionTypes.CLEAR_TOKEN:
      return produce(state, (draft) => {
        draft.token = null;
      });
    case actionTypes.SHOW_LOADING_SPINNER:
      return produce(state, (draft) => {
        draft.showLoadingSpinner = action.payload;
      });
    case actionTypes.SUBMIT_FEEDBACK_OVERLAY:
      return produce(state, (draft) => {
        draft.submitFeedbackOverlayActive = action.payload;
      });
    case actionTypes.HOW_IT_WORKS:
      return produce(state, (draft) => {
        draft.howItWorksActive = action.payload;
      });
    case actionTypes.EXPORTING_STATUS:
      return produce(state, (draft) => {
        draft.exportingInProgress = action.payload;
      });
    case actionTypes.ACTIVE_MULTISELECT_DROPDOWN:
      return produce(state, (draft) => {
        draft.activeMultiselectDropdown = action.payload;
      });
    default:
      return state;
  }
};
