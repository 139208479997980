export const SHOW_LOADING_SPINNER = 'SHOW_LOADING_SPINNER';
export const SUBMIT_FEEDBACK_OVERLAY = 'SUBMIT_FEEDBACK_OVERLAY';
export const HOW_IT_WORKS = 'HOW_IT_WORKS';
export const GOD_CLICKED = 'GOD_CLICKED';
export const GOD_CLICKED_RESET = 'GOD_CLICKED_RESET';
export const EXPORTING_STATUS = 'EXPORTING_STATUS';
export const EXPORTING_PDF = 'EXPORTING_PDF';
export const ACTIVE_MULTISELECT_DROPDOWN = 'ACTIVE_MULTISELECT_DROPDOWN';

export const EXPORTING_EXCEL = 'EXPORTING_EXCEL';
export const EXPORTING_EXCEL_SUCCESS = 'EXPORTING_EXCEL_SUCCESS';
export const EXPORTING_EXCEL_FAIL = 'EXPORTING_EXCEL_FAIL';

export const EXPORT_TO_PDF = 'EXPORTING_TO_PDF';
export const EXPORT_TO_PDF_SUCCESS = 'EXPORTING_TO_PDF_SUCCESS';
export const EXPORT_TO_PDF_FAIL = 'EXPORTING_TO_PDF_FAIL';

export const SET_TOKEN = 'SET_TOKEN';
export const CLEAR_TOKEN = 'CLEAR_TOKEN';

// Home
export const LOAD_JSON_FILE = 'LOAD_JSON_FILE';
export const LOAD_JSON_FILE_SUCCESS = 'LOAD_JSON_FILE_SUCCESS';
export const LOAD_JSON_FILE_FAIL = 'LOAD_JSON_FILE_FAIL';

export const SEARCH_PARAMS_CHANGED = 'SEARCH_PARAMS_CHANGED';
export const SUBMIT_SEARCH_PARAMS = 'SUBMIT_SEARCH_PARAMS';
export const SUBMIT_SEARCH_PARAMS_SUCCESS = 'SUBMIT_SEARCH_PARAMS_SUCCESS';
export const SUBMIT_SEARCH_PARAMS_FAIL = 'SUBMIT_SEARCH_PARAMS_FAIL';
export const RESET_SEARCH_STATUS = 'RESET_SEARCH_STATUS';

// Search
export const EXPORT_TO_PDF_OVERLAY = 'EXPORT_TO_PDF_OVERLAY';
export const CLEAR_ALL_FILTERS = 'CLEAR_ALL_FILTERS';
export const SET_FILTERS_CLEARED = 'SET_FILTERS_CLEARED';
export const REMOVE_SEARCH_RESULT = 'REMOVE_SEARCH_RESULT';
export const UNDO_REMOVED_SEARCH_RESULT = 'UNDO_REMOVED_SEARCH_RESULT';

// Search Process Detail
export const LOAD_SEARCH_PROCESSDETAIL = 'LOAD_SEARCH_PROCESSDETAIL';
export const LOAD_SEARCH_PROCESSDETAIL_SUCCESS =
  'LOAD_SEARCH_PROCESSDETAIL_SUCCESS';
export const LOAD_SEARCH_PROCESSDETAIL_FAIL = 'LOAD_SEARCH_PROCESSDETAIL_FAIL';

export const REFRESH_SEARCH_PROCESSDETAIL = 'REFRESH_SEARCH_PROCESSDETAIL';

// Processes
export const LOAD_PROCESSES = 'LOAD_PROCESSES';
export const LOAD_PROCESSES_SUCCESS = 'LOAD_PROCESSES_SUCCESS';
export const LOAD_PROCESSES_FAIL = 'LOAD_PROCESSES_FAIL';

export const PROCESSES_CREATE_OVERLAY = 'PROCESSES_CREATE_OVERLAY';
export const CREATE_PROCESSES_LOADING = 'CREATE_PROCESSES_LOADING';
export const UPDATE_CREATEPROCESSES_FORM = 'UPDATE_CREATEPROCESSES_FORM';
export const ADD_PROCESSES_SUCCESSFUL = 'ADD_PROCESSES_SUCCESSFUL';
export const ADD_PROCESSES_FAIL = 'ADD_PROCESSES_FAIL';
export const DELETE_PROCESS_OVERLAY = 'DELETE_PROCESS_OVERLAY';
export const PROCESS_TO_DELETE = 'PROCESS_TO_DELETE';

export const SEARCH_DATA = 'SEARCH_DATA';
export const SEARCH_DATA_SUCCESS = 'SEARCH_DATA_SUCCESS';
export const SEARCH_DATA_FAIL = 'SEARCH_DATA_FAIL';

export const RESET_SEARCH = 'RESET_SEARCH';
export const SORT_BY_LAST_UPDATED = 'SORT_BY_LAST_UPDATED';

// Process Detail
export const SET_PROCESS_FILE_ERRORS = 'SET_PROCESS_FILE_ERRORS';
export const LOAD_PROCESSDETAIL = 'LOAD_PROCESSDETAIL';
export const LOAD_PROCESSDETAIL_SUCCESS = 'LOAD_PROCESSDETAIL_SUCCESS';
export const LOAD_PROCESSDETAIL_FAIL = 'LOAD_PROCESSDETAIL_FAIL';

export const ANSWER_EDIT_OVERLAY = 'ANSWER_EDIT_OVERLAY';
export const ANSWER_TO_EDIT = 'ANSWER_TO_EDIT';

export const UPDATE_COMMENT = 'UPDATE_COMMENT';
export const UPDATE_COMMENT_SUCCESSFUL = 'UPDATE_COMMENT_SUCCESSFUL';
export const UPDATE_COMMENT_FAIL = 'UPDATE_COMMENT_FAIL';

export const COMMENT_EDIT_OVERLAY = 'COMMENT_EDIT_OVERLAY';
export const COMMENT_TO_EDIT = 'COMMENT_TO_EDIT';

export const UPDATE_ANSWER = 'UPDATE_ANSWER';
export const UPDATE_ANSWER_SUCCESSFUL = 'UPDATE_ANSWER_SUCCESSFUL';
export const UPDATE_ANSWER_FAIL = 'UPDATE_ANSWER_FAIL';

export const DELETE_PROCESS = 'DELETE_PROCESS';
export const DELETE_PROCESS_SUCCESSFUL = 'DELETE_PROCESS_SUCCESSFUL';
export const DELETE_PROCESS_FAIL = 'DELETE_PROCESS_FAIL';

export const REFERENCES_EDIT_OVERLAY = 'REFERENCES_EDIT_OVERLAY';
export const REFEREMCES_TO_EDIT = 'REFEREMCES_TO_EDIT';

export const UPDATE_REFERENCES = 'UPDATE_REFERENCES';
export const UPDATE_REFERENCES_SUCCESSFUL = 'UPDATE_REFERENCES_SUCCESSFUL';
export const UPDATE_REFERENCES_FAIL = 'UPDATE_REFERENCES_FAIL';

export const NUMBER_OF_BODIES_EDIT_OVERLAY = 'NUMBER_OF_BODIES_EDIT_OVERLAY';
export const NUMBER_OF_BODIES_TO_EDIT = 'NUMBER_OF_BODIES_TO_EDIT';

export const UPDATE_NUMBER_OF_BODIES = 'UPDATE_NUMBER_OF_BODIES';
export const UPDATE_NUMBER_OF_BODIES_SUCCESSFUL =
  'UPDATE_NUMBER_OF_BODIES_SUCCESSFUL';
export const UPDATE_NUMBER_OF_BODIES_FAIL = 'UPDATE_NUMBER_OF_BODIES_FAIL';

export const ALTERNATIVE_NAMES_OVERLAY = 'ALTERNATIVE_NAMES_OVERLAY';
export const ALTERNATIVE_NAMES_TO_EDIT = 'ALTERNATIVE_NAMES_TO_EDIT';

export const UPDATE_ALTERNATIVE_NAMES = 'UPDATE_ALTERNATIVE_NAMES';
export const UPDATE_ALTERNATIVE_NAMES_SUCCESSFUL =
  'UPDATE_ALTERNATIVE_NAMES_SUCCESSFUL';
export const UPDATE_ALTERNATIVE_NAMES_FAIL = 'UPDATE_ALTERNATIVE_NAMES_FAIL';

export const UPDATE_IMPORT_EXCEL_FORM = 'UPDATE_IMPORT_EXCEL_FORM';
export const IMPORT_OVERLAY = 'IMPORT_OVERLAY';

export const IMPORT_PROCESSES = 'IMPORT_PROCESSES';
export const IMPORT_PROCESSES_FAILED = 'IMPORT_PROCESSES_FAILED';
export const IMPORT_PROCESSES_SUCCESSFUL = 'IMPORT_PROCESSES_SUCCESSFUL';

export const UNDO_EDIT = 'UNDO_EDIT';
export const UNDO_EDIT_SUCCESS = 'UNDO_EDIT_SUCCESS';
export const UNDO_EDIT_FAIL = 'UNDO_EDIT_FAIL';

export const UNDO_OVERLAY = 'UNDO_OVERLAY';
export const UNDO_TO_UNDO = 'UNDO_TO_UNDO';

// Properties
export const LOAD_PROPERTIES = 'LOAD_PROPERTIES';
export const LOAD_PROPERTIES_SUCCESS = 'LOAD_PROPERTIES_SUCCESS';
export const LOAD_PROPERTIES_FAIL = 'LOAD_PROPERTIES_FAIL';

export const DELETE_PROPERTY = 'DELETE_PROPERTY';
export const DELETE_PROPERTY_SUCCESS = 'DELETE_PROPERTY_SUCCESS';
export const DELETE_PROPERTY_FAIL = 'DELETE_PROPERTY_FAIL';

export const PROPERTY_CREATE_OVERLAY = 'PROPERTY_CREATE_OVERLAY';
export const CREATE_PROPERTY_LOADING = 'CREATE_PROPERTY_LOADING';
export const UPDATE_CREATEPROPERTY_FORM = 'UPDATE_CREATEPROPERTY_FORM';

export const ADD_PROPERTY = 'ADD_PROPERTY';
export const ADD_PROPERTY_SUCCESSFUL = 'ADD_PROPERTY_SUCCESSFUL';
export const ADD_PROPERTY_FAIL = 'ADD_PROPERTY_FAIL';

export const PROPERTY_EDIT_FORM = 'PROPERTY_EDIT_FORM';
export const PROPERTY_EDIT_FORM_SUCCESS = 'PROPERTY_EDIT_FORM_SUCCESS';
export const PROPERTY_EDIT_FORM_FAIL = 'PROPERTY_EDIT_FORM_FAIL';

export const PROPERTY_SORT = 'PROPERTY_SORT';

export const SEARCH_DATA_PROPERTIES = 'SEARCH_DATA_PROPERTIES';
export const SEARCH_DATA_PROPERTIES_SUCCESS = 'SEARCH_DATA_PROPERTIES_SUCCESS';
export const SEARCH_DATA_PROPERTIES_FAIL = 'SEARCH_DATA_PROPERTIES_FAIL';

export const ACTIVE_PROPERTY = 'ACTIVE_PROPERTY';
export const ACTIVE_PROPERTY_SUCCESS = 'ACTIVE_PROPERTY_SUCCESS';
export const ACTIVE_PROPERTY_FAIL = 'ACTIVE_PROPERTY_FAIL';

// Tags
export const TAG_REMOVED = 'TAG_REMOVED';
export const TAG_REMOVED_RESET = 'TAG_REMOVED_RESET';

// Feedback
export const FEEDBACK_OVERLAY = 'FEEDBACK_OVERLAY';
export const FEEDBACK_SUCCESSFUL = 'FEEDBACK_SUCCESSFUL';

// Pinboard
export const PIN_PROCESS = 'PIN_PROCESS';
export const PIN_STAGE = 'PIN_STAGE';
export const PIN_PROPERTY = 'PIN_PROPERTY';
export const PINBOARD_OVERLAY = 'PINBOARD_OVERLAY';
export const CLEAR_PINBOARD = 'CLEAR_PINBOARD';
export const PIN_STAGES_OVERLAY = 'PIN_STAGES_OVERLAY';

// Filters
export const GET_FILTER_LIST = 'GET_FILTER_LIST';
export const GET_FILTER_LIST_SUCCESS = 'GET_FILTER_LIST_SUCCESS';
export const GET_FILTER_LIST_FAIL = 'GET_FILTER_LIST_FAIL';

export const UPDATE_FILTER_LIST = 'UPDATE_FILTER_LIST';
export const UPDATE_FILTER_LIST_SUCCESS = 'UPDATE_FILTER_LIST_SUCCESS';
export const UPDATE_FILTER_LIST_FAIL = 'UPDATE_FILTER_LIST_FAIL';
